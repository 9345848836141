import React from "react"
import tw from "twin.macro"

import { StaticImage } from "gatsby-plugin-image"

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto my-6 lg:my-12 `
const Column = tw.div`items-center h-80 md:h-auto`

export default () => {
  return (
    <Container>
      <Content>
        <Column>
          <StaticImage src="../images/404error.jpg" width={1600} height={900} />
        </Column>
      </Content>
    </Container>
  )
}
