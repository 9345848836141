import React from "react"
import AnimationRevealPage from "../components/helpers/AnimationRevealPage.jsx"
import Header from "../components/Header.jsx"
import Footer from "../components/Footer.jsx"
import SEO from "../components/misc/SEO.jsx"
import NotFound from "../components/NotFound.jsx"

export default () => {
  return (
    <AnimationRevealPage disabled>
      <SEO title="404 Not Found" />
      <Header />
      <NotFound />
      <Footer />
    </AnimationRevealPage>
  )
}
